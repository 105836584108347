@font-face {
  font-family: "Consolas";
  src: url("Consolas Italic.woff2") format("woff2"),
  url("Consolas Italic.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
  unicode-range: U+0020-2083;
}

@font-face {
  font-family: "Consolas";
  src: url("Consolas Bold.woff2") format("woff2"),
  url("Consolas Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-2083;
}

@font-face {
  font-family: "Consolas";
  src: url("Consolas Bold Italic.woff2") format("woff2"),
  url("Consolas Bold Italic.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
  unicode-range: U+0020-2083;
}

@font-face {
  font-family: "Consolas";
  src: url("Consolas Italic.woff2") format("woff2"),
  url("Consolas Italic.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
  unicode-range: U+0020-2083;
}

@font-face {
  font-family: "Consolas";
  src: url("Consolas.woff2") format("woff2"),
  url("Consolas.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-2083;
}