.iframe {
  width: 100%;
  height: 100%;
  display: block;
  position: relative;

  &:not(.active) {
    &:hover::before {
      top: 50%;
      left: 50%;
      z-index: 1;
      color: white;
      padding: 2rem;
      font-size: 2rem;
      font-weight: 700;
      position: absolute;
      content: "Click to scroll";
      transform: translate(-50%, -50%);
      background-color: rgba(0, 0, 0, 0.80);
    }

    iframe {
      overflow-y: hidden;
      pointer-events: none;
    }
  }
}
