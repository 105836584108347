.header {
  height: 7rem;
  display: flex;
  padding: 0 2rem;
  justify-content: flex-end;
  max-width: 80rem;
  width: 100%;
  margin: 0 auto;


  .header__title {
    display: none;
  }

  @media (min-width: $width-desktop-small) {
    > * {
      flex: 1;
    }

    .header__title {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
  }


}
