/* v1.0.0
Lyttle Eggs ~ Fun jokes for developers 😌
https://github.com/Stualyttle/LyttleTools/blob/main/CSS/eggs.css
*/

@media (max-width: 200px) {
  #root {
    display: none
  }

  * {
    background-color: black !important;
  }

  body:before {
    left: 50%;
    top: 0;
    padding-left: 20%;
    width: 90vh;
    color: white;
    display: block;
    position: absolute;
    white-space: nowrap;
    transform-origin: 0 60%;
    content: "You tried! 😌";
    font-size: 4vh;
    transform: rotate(90deg) translateY(50%);
  }
}

@media (max-width: 150px) {
  body:before {
    content: "Still going? 😡";
  }
}

@media (max-width: 120px) {
  body:before {
    content: "Why even? 😢";
  }
}

@media (max-width: 90px) {
  body:before {
    content: "Wait what? 😮";
  }
}

@media (max-width: 60px) {
  body:before {
    content: "Not going to stop are you? 😒";
  }
}

@media (max-width: 50px) {
  body:before {
    content: "Why even? There's noting here! 🥱";
  }
}

@media (max-width: 30px) {
  body:before {
    content: "Ok Fine... you win, you found them all! 🥳";
  }
}
