h1,
h2,
.subtitle {
  display: block;
}

h1,
.txt__title {
  font-weight: 700;
  color: $color-green-300;
}

.txt__title {
  font-size: 2rem;
}

h2 {
  color: $color-green-300;
  font-weight: 700;
  margin-left: 2rem;
}