@import '../fonts/Consolas';
@import '../fonts/Poppins';

body {
  font-family: 'Poppins', Helvetica, Arial, sans-serif;
  font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6,
input,
label,
textarea,
select,
button,
.btn {
  font-family: 'Poppins', Helvetica, Arial, sans-serif;
  font-weight: 400;
}