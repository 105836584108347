@font-face {
  font-family: "Poppins";
  src: url("Poppins Bold.woff2") format("woff2"),
  url("Poppins Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("Poppins Bold Italic.woff2") format("woff2"),
  url("Poppins Bold Italic.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Poppins";
  src: url("Poppins Italic.woff2") format("woff2"),
  url("Poppins Italic.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Poppins";
  src: url("Poppins Regular.woff2") format("woff2"),
  url("Poppins Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("Poppins Black.woff2") format("woff2"),
  url("Poppins Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("Poppins Black Italic.woff2") format("woff2"),
  url("Poppins Black Italic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Poppins";
  src: url("Poppins ExtraBold.woff2") format("woff2"),
  url("Poppins ExtraBold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("Poppins ExtraBold Italic.woff2") format("woff2"),
  url("Poppins ExtraBold Italic.woff") format("woff");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Poppins";
  src: url("Poppins ExtraLight.woff2") format("woff2"),
  url("Poppins ExtraLight.woff") format("woff");
  font-weight: 275;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("Poppins ExtraLight Italic.woff2") format("woff2"),
  url("Poppins ExtraLight Italic.woff") format("woff");
  font-weight: 275;
  font-style: italic;
}

@font-face {
  font-family: "Poppins";
  src: url("Poppins Light.woff2") format("woff2"),
  url("Poppins Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("Poppins Light Italic.woff2") format("woff2"),
  url("Poppins Light Italic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Poppins";
  src: url("Poppins Medium.woff2") format("woff2"),
  url("Poppins Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("Poppins Medium Italic.woff2") format("woff2"),
  url("Poppins Medium Italic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Poppins";
  src: url("Poppins SemiBold.woff2") format("woff2"),
  url("Poppins SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("Poppins SemiBold Italic.woff2") format("woff2"),
  url("Poppins SemiBold Italic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Poppins";
  src: url("Poppins Thin.woff2") format("woff2"),
  url("Poppins Thin.woff") format("woff");
  font-weight: 250;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("Poppins Thin Italic.woff2") format("woff2"),
  url("Poppins Thin Italic.woff") format("woff");
  font-weight: 250;
  font-style: italic;
}