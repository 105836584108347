/* v1.0.2
Lyttle Defaults ~ Recommended to use with Lyttle Reset:
https://github.com/Stualyttle/LyttleTools/blob/main/CSS/reset.css
*/

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* word-wrap: break-word; */
}

body {
  line-height: 1;
  font-family: Helvetica, Arial, sans-serif;
}

#root {
  display: flex;
  flex-direction: column;
}

main {
  flex-grow: 1;
}

h1 {
  font-size: 3.052rem
}

h2 {
  font-size: 2.441rem
}

h3 {
  font-size: 1.953rem
}

h4 {
  font-size: 1.563rem
}

h5 {
  font-size: 1.563rem
}

p {
  font-size: 1rem
}
